<template>
  <v-container>
    <!-- 期限切れ -->
    <v-card v-if="state == 'expired'">
      <v-card-text>
        <div class="text-caption mb-4">メール変更リクエスト期限が切れています。</div>
      </v-card-text>
    </v-card>
    <!-- 新規パスワード入力 -->
    <v-card
      v-if="state == 'input'"
      max-width="374"
      class="mx-auto"
    >
      <v-form ref="form">
        <v-card-title>パスワード再設定画面</v-card-title>
        <v-card-text>
          <v-text-field
            label="新しいパスワード"
            v-model="newPassword"
            autocomplete
            :type="isNewPasswordShow ? 'text' : 'password'"
            :append-icon="isNewPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="isNewPasswordShow = !isNewPasswordShow"
          />
          <v-text-field
            label="新しいパスワード（確認用）"
            v-model="confirmPassword"
            autocomplete
            :type="isConfirmPasswordShow ? 'text' : 'password'"
            :append-icon="isConfirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="isConfirmPasswordShow = !isConfirmPasswordShow"
            :rules="passwordConfirmRule"
          />
        </v-card-text>
        <v-card-actions class="mb-5">
          <v-btn
            @click.prevent="sendConfirm"
            block
            color="primary"
          >
            パスワードを再設定する
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <!-- 設定完了 -->
    <v-card 
      v-if="state == 'complete'"
      max-width="374"
      class="mx-auto"
    >
      <v-card-text>
        <div class="text-caption mb-4">パスワードを変更いたしました。</div>
        <div class="text-center">
          <router-link :to="{name: 'event'}">イベントページに戻る</router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default{
  name: 'reset',

  data(){
    return{
      state: '',
      eventId: '',
      token: '',
      newPassword: "",
      confirmPassword: "",
      isNewPasswordShow: false,
      isConfirmPasswordShow: false
    }
  },
  computed:{
    passwordConfirmRule(){
      return () => (this.newPassword === this.confirmPassword) || 'パスワードが一致していません'
    }
  },
  async created(){
    this.eventId = this.$route.params.event_id
    this.token = this.$route.params.token
    const purchase = await this.wc.get('v_purchase', `w/event_id,${this.eventId}:token,${this.token}`).catch((err) => {
      console.log(err)
    })
    this.state = new Date(purchase.reset_token_expired_at) < new Date() ? 'expired' : 'input'
  },
  methods:{
    async sendConfirm(){
      if(!this.$refs.form.validate()) return
      const postData = [{
        token: this.token,
        password: this.newPassword
      }]
      const res = await this.wc.post('reset', postData).catch((err) => {
        console.log(err)
      })
      if(res.changes) this.state = 'complete'
    }
  }
}
</script>
<style></style>
